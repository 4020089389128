.leaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 20px 20px 10px;
  background: #FFF4E5;
  transition: all ease-in-out 300ms;
  border-radius: 4px;
  border: 2px solid #000;
  max-width: 790px;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  font-size: 24px;
  font-style: italic;
}

.leaderContainer:hover {
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  margin-left: 10px;
  flex-wrap: wrap;
}

.nameHandle {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: left;
}

.ranking {
  width: 40px;
  font-size: 24px;
  font-style: italic;
}

@media(min-width: 700px) {
  .leaderContainer {
    padding: 20px 40px 20px 10px;
  }
  .user {
    gap: 20px;
  }
  .nameHandle {
    flex-direction: row;
    align-items: center;
  }
  .ranking {
    width: 56px;
  }
}

.first {
  background: linear-gradient(to right, #FFF0BF, #FFDA8D);
  transition: all ease-in-out 200ms;
}

.second {
  background: linear-gradient(to right, #E1ECF2, #C0C4C7);
  transition: all ease-in-out 200ms;
}

.third {
  background: linear-gradient(to right, #D2B4A3, #CE9F85);
  transition: all ease-in-out 200ms;
}

.avatar {
  border: 2px solid black;
  height: 72px;
  width: 72px;
}

.memeCount {
  font-size: 24px;
  margin-left: 10px;
}

.twitterUser {
  color: #C64646;
  font-size: 18px;
}

.name, .twitterUser {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
