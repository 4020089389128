.profileContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid black;
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px -1px #F9E44B, 3px 3px 0px 1px #000000;
    border-radius: 10px;
    height: 95px;
    overflow: hidden;
    padding: 0 10px 0 0;
    width: 100%;
    max-width: 450px;
    font-weight: normal;
    font-style: italic;
}

.photo {
    height: 100%;
    display: flex;
    align-items: center;
}

.photo img {
    height: 100%;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
    border-right: 2px solid black;
}

.profileDetails {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
}

.memeCount {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
}

.red {
    padding-top: 8px;
    color: #A95050;
    font-size: 16px;
}

.name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
