.container {
  align-items: center;
  justify-content: center;
  border: 2px black solid;
  border-radius: 10px;
  width: 100%;
  position: relative;
  padding-bottom: 100%; /* Sets the height equal to the width */
  overflow: hidden;

  --color-1: #B6BDFF;
  --color-2: #7FC2BA;
  --color-3: #D1B9A5;
  --color-4: #FFF6B8;
  --color-5: #D5A6A6;
  --color-6: #B4D8AA;
  --color-7: #BEA8CF;
  --color-8: #B1C2FF;
  --color-9: #F5DD8F;
  --color-10: #FED3A1;
  --color-11: #FDCECE;

  &:nth-of-type(1) {
    background-color: var(--color-1);
  }
  &:nth-of-type(2) {
    background-color: var(--color-2);
  }
  &:nth-of-type(3) {
    background-color: var(--color-3);
  }
  &:nth-of-type(4) {
    background-color: var(--color-4);
  }
  &:nth-of-type(5) {
    background-color: var(--color-5);
  }
  &:nth-of-type(6) {
    background-color: var(--color-6);
  }
  &:nth-of-type(7) {
    background-color: var(--color-7);
  }
  &:nth-of-type(8) {
    background-color: var(--color-8);
  }
  &:nth-of-type(9) {
    background-color: var(--color-9);
  }
  &:nth-of-type(10) {
    background-color: var(--color-10);
  }
  &:nth-of-type(11) {
    background-color: var(--color-11);
  }
  &:nth-of-type(12) {
    background-color: var(--color-1);
  }
  &:nth-of-type(13) {
    background-color: var(--color-2);
  }
  &:nth-of-type(14) {
    background-color: var(--color-3);
  }
  &:nth-of-type(15) {
    background-color: var(--color-4);
  }
  &:nth-of-type(16) {
    background-color: var(--color-5);
  }

  &:nth-of-type(17) {
    background-color: var(--color-6);
  }
  &:nth-of-type(18) {
    background-color: var(--color-7);
  }
  &:nth-of-type(19) {
    background-color: var(--color-8);
  }
  &:nth-of-type(20) {
    background-color: var(--color-9);
  }
  &:nth-of-type(21) {
    background-color: var(--color-10);
  }
  &:nth-of-type(22) {
    background-color: var(--color-11);
  }
  &:nth-of-type(23) {
    background-color: var(--color-1);
  }
  &:nth-of-type(24) {
    background-color: var(--color-2);
  }

}

.container:hover .overlay {
  opacity: 1;
}

.container img {
  position: absolute;
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  object-fit: contain; /* Ensures the image covers the entire container */
}

.download {
  border: 2px solid black;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  color: black;
  margin-bottom: 40px;
}

.container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(83, 124, 242, 0.5); /* Semi-transparent overlay */
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.5em;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px; /* Match the container's border radius */
}

