.memeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10px;
}

p {
  margin-top: 0;
}

@media(min-width: 900px) {
  .memeGrid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
}


@media(min-width: 1200px) {
  .memeGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
}

.memeAsset {
  max-width: max-content;
  margin: 100px 10px;
}

.memeAsset {
  background: #ED8B70;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  color: #fff;
  position: relative;
  border-radius: 10px;
  border: 2px solid transparent;
  font-size: 28px;
  transition: all ease-in-out 150ms;
  transform: rotate(-1deg);
}

@media(min-width: 900px) {
  .memeAsset {
    font-size: 40px;
    padding: 20px 60px
  }
}

@media(min-width: 1200px) {
  .memeAsset {
    font-size: 60px;
    padding: 20px 100px;
  }
}

.memeAsset:after {
  position: absolute;
  left: 10px;
  top: -10px;
  content: '';
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 10px;
}

.makeMemes {
  background: #ED8B70;
  padding: 20px 40px;
  font-size: 30px;
  border-radius: 10px;
  color: #fff;
  margin-top: 60px;
  transform: rotate(1deg);
  position: relative;
  border: 2px solid black;
  transition: all ease-in-out 200ms;
}

.makeMemes:hover {
  transform: rotate(0);
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
}

.hashtag {
  font-size: 30px;
}

.howItWorks {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.howItWorksImage {
  width: 100px;
}

.howItWorks > img {
  transition: ease-in-out 300ms all;
}

.howItWorks:hover > img {
  transform: scale(1.1);
}

.howItWorksText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.howItWorksText h2 {
  font-size: 24px;
  padding-left: 40px;
  color: #FFD796;
  text-shadow: 2px 2px 2px #000000;  
  margin-top: 8px;
  letter-spacing: 2px;
  -webkit-text-stroke: 2px #000;
}

.worksArrow {
  width: 75px;
}

@media(min-width: 600px) {
  .howItWorksImage {
    width: 140px;
  }

  .howItWorksText h2 {
    font-size: 32px;
  }

  .worksArrow {
    width: 80px;
  }
}

.inner {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}