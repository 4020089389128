.submitContainer {
  display: flex;
  width: 100%;
}

.right, .choke, .arrowLeft, .left, .dicky, .arrowRight {
  display: none;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 40px;
  flex-grow: 1;
}

.choke, .stick {
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}



@media(min-width: 1100px) {


  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 290px;
  }

  .stick {
    display: block;
    color: #FFD792;
    font-size: 24px;
    max-width: 200px;
    transform: rotate(-20deg);
  }

  .arrowRight {
    display: block;
    width: 106px;
    margin-left: auto;
  }
  
  .dicky {
    display: block;
    max-width: 190px;
    padding-right: 30px;
  }
}

@media(min-width: 1300px) {
  .center {
    flex-grow: 0;
  }

  .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 120px;
    padding-left: 40px;
    width: 215px;
  }

  .choke {
    display: block;
    color: #FFBA9F;
    font-size: 24px;
    max-width: 200px;
    transform: rotate(10deg);
    margin-left: auto;
  }

  .arrowLeft {
    display: block;
    width: 91px;
    margin-right: auto;
  }
  .center {

    margin: 0;
  }
}



.stick, .choke {
  font-weight: normal;
}

.inputContainer {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
}


.input {
  font-size: 20px;
  width: 100%;
  outline: none;
  padding: 0 20px;
  height: 76px;
  border-radius: 10px 0 0 10px;
  border: 2px solid black;
  border-right: none;

}

.input:focus-visible {
  outline: none;
  border: 2px solid black;
  border-right: none;
}

.submitButton {
  width: 80px;
  height: 76px;
  background: #ED8B70;
  border-radius: 0 10px 10px 0;
  border: 2px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton > img {
  width: 36px;
  object-fit: contain;
  transform: rotate(-15deg);
  transition: ease-in-out 200ms all;
}

.submitButton > img:hover {
  transform: scale(1.2) rotate(30deg);
}

.error, .success {
  font-size: 20px;
  padding-top: 10px;
}

.error > button {
  margin-left: 10px;

}

.error {
  color: red;
}

.success {
  color: green;
}

.closed {
  font-size: 20px;
  padding-top: 10px;
  color: #ED8B70;
}
