.bannerContainer {
  width: 100%;
  overflow: hidden;
  display: flex;
  gap: 20px;
  height: 78px;
  border-bottom: 6px solid black;
  align-items: center;
  justify-content: center;
  background: #F6D8A4;
  margin: 90px 0;
}

.text {
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  flex-grow: 2;
  display: flex;
  width: max-content;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}

.image {
  width: 40px;
}


