.container {
  background: #EEC2CE;
  border: 2px solid #000000;
  border-radius: 10px;
  height: 40px;
  margin: 60px 0 0 0;
  overflow: hidden;
  max-width: 700px;
}

.progressBar {
  height: 36px;
  transition: width 0.5s ease-in-out;
  margin-right: auto;
  overflow: hidden;
}

.label {
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
}

.progressText {
  margin: 20px;
}