.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.inner {
  width: 95%;
  max-width: 1000px;
  max-height: 90%;
  width: 90%;
  background: #fff;
  padding: 90px 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 4px solid black;
  background: #FFF4E5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media(min-width: 900px) {
  .inner {
    padding: 90px;
  }
}