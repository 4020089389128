.login {
  padding: 10px 30px;
  font-style: normal;
  background: #DE859E;
  border: 2px solid #000000;
  box-shadow: 3px 3px 0px -1px #C1FB6A, 3px 3px 0px 1px #000000;
  border-radius: 5px;
  color: #fff;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all ease-in-out 250ms;
}

.login a {
  color: #fff;
  text-decoration: none;
}

.login:hover {
  cursor: pointer;
  transform: scale(1.1);
}