.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 96px;
    flex-wrap: wrap;
}

@media(min-width: 800px) {
    .header {
        padding: 20px 40px;
    }
}

.left {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navList {
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-style: italic;
    align-items: center;
}

.navList > a {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 18px 24px;
}

.active {
    color: #fff;
    position: relative;
}

.active:nth-child(odd)::before {
    content: "";
    background: #000;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    transform: rotate(-1deg); /* Rotate odd elements by -1 degree */
    border-radius: 4px;
}

.active:nth-child(even)::before {
    content: "";
    background: #000;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    transform: rotate(1deg); /* Rotate even elements by 1 degree */
    border-radius: 4px;
}



.logo {
    height: 64px;
    width: auto;
}

.twitterLink {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: max-content;
}

.navList > .twitterLinkMobile {
    display: none;

}

.mobileNavOpen > .twitterLinkMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: max-content;
}


.twitterLogo {
    background: black;
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.twitterLogo > img {
    height: 14px;
}

.mobileMenuButton {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.mobileNavOpen {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffe2;
    backdrop-filter: blur(10px);
    border-top: 1px solid #ccc;
    z-index: 1000;
}

.closeButton {
    display: none;
    font-size: 40px;
}

.mobileNavOpen > .closeButton {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
}

.mobileNavOpen {
    font-size: 24px;
}

@media (max-width: 1200px) {
    .navList {
        display: none;
    }

    .mobileMenuButton {
        display: block;
    }
    .mobileNavOpen {
        display: flex;
    }

    .twitterLink {
        display: none
    }
}
