.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
}

.walletLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  justify-self: center;
}

.walletLink > img {
  width: 200px;
}

.inner {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}