.scrollContainer {
  max-width: 800px;
}

.boardHeader > img {
  width: 206px;
}

.boardHeader {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  padding: 0 20px 0 20px;
  justify-content: space-between;
  gap: 10px;
}

.boardHeader > span {
  padding-bottom: 20px;
}

.rankHeader, .profileHeader {
  display: none;
}

@media(min-width: 700px) {
  .rankHeader{
    display: block;
    width: 56px;
  }
  .profileHeader {
    display: block;
  }
}